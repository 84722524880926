import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useState } from "react"
import styled, { css } from "styled-components"

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    z-index: 99;
    align-self: flex-start;
    padding: 0rem 2rem;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    margin: 0;

    @media only screen and (max-width: 768px) {
      position: fixed;
      right: 0;
      top: 0;

      height: 100%;
      width: 100%;

      flex-direction: column;

      padding: 6rem 6rem;
      background-color: white;

      transition: 0.2s ease-out;

      transform: ${({ drawerIsOpen }) =>
        drawerIsOpen ? `translateY(0)` : `translateY(-100%)`};
    }
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
    margin: 0;

    > :link {
      text-decoration: none;
      color: #000;
    }

    > :visited {
      text-decoration: none;
      color: #000;
    }

    @media only screen and (max-width: 768px) {
      padding: 2rem 1rem;
      text-align: center;
    }
  `,
}
const NameWebsiteBtn = styled.h1`
  font-weight: 700;
  margin: 0;

  > :link {
    text-decoration: none;
    color: #000;
  }

  > :visited {
    text-decoration: none;
    color: #000;
  }
`
const HamburgerButton = {
  Wrapper: styled.div`
    width: 24px;
    height: 24px;
    position: relative;
    @media only screen and (min-width: 769px) {
      display: none;
    }

    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    cursor: pointer;
    z-index: 10000000000000;

    > :nth-child(2),
    > :nth-child(3) {
      top: 10px;
    }

    > :nth-child(4) {
      bottom: 0;
    }

    ${({ drawerIsOpen }) =>
      drawerIsOpen &&
      css`
        > :nth-child(1) {
          top: 12px;
          width: 0%;
          left: 50%;
        }

        > :nth-child(2) {
          transform: rotate(45deg);
        }

        > :nth-child(3) {
          transform: rotate(-45deg);
        }

        > :nth-child(4) {
          top: 12px;
          width: 0%;
          left: 50%;
        }
      `}
  `,
  Line: styled.div`
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  `,
}

function Header({ siteTitle }) {
  const [drawerIsOpen, toggleDrawer] = useState(false)

  const links = [
    {
      label: "About",
      path: "/#about",
    },
    {
      label: "Projects",
      path: "/#projects",
    },
    {
      label: "Work experience",
      path: "/#work-experience",
    },
    {
      label: "Contact",
      path: "/#contact",
    },
  ]

  return (
    <Navbar.Wrapper>
      <NameWebsiteBtn>
        <AnchorLink to="/">{siteTitle}</AnchorLink>
      </NameWebsiteBtn>
      <HamburgerButton.Wrapper
        drawerIsOpen={drawerIsOpen}
        onClick={() => toggleDrawer(!drawerIsOpen)}
      >
        {[1, 2, 3, 4].map(int => (
          <HamburgerButton.Line key={int} />
        ))}
      </HamburgerButton.Wrapper>

      <Navbar.Items drawerIsOpen={drawerIsOpen}>
        {links.map(({ label, path }) => (
          <Navbar.Item key={label} onClick={() => toggleDrawer(false)}>
            <AnchorLink to={path}>{label}</AnchorLink>
          </Navbar.Item>
        ))}
      </Navbar.Items>
    </Navbar.Wrapper>
  )
}

export default Header
